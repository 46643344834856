$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);
@import '@/styles/_globals';

.MenuListItem {
  width: 100%;
  line-height: 1.5;
  padding: $buttonPaddingVertical $spacing;
  position: relative;
  cursor: pointer;
  white-space: nowrap;

  &::after {
    content: '';
    display: block;
    top: 0;
    right: 0;
    width: 2px;
    height: 100%;
    position: absolute;
  }

  @include media('<widescreen') {
    padding: ($spacing * 0.5);
  }

  img {
	margin-right: 5px;
  }
}

.MenuListOverlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem;
  padding-left: 0;
  @include overflowTouch('hidden', 'auto');
}
