$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);
@import '@/styles/_globals';

.SidebarButton {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  transition: all 200ms ease;
  padding: $buttonPaddingVertical $buttonPaddingHorizontal;
  text-transform: uppercase;
  letter-spacing: 1px;

  .Icon {
    min-width: 1rem;
    max-width: 1rem;
    height: 1rem;
    margin-left: 0 !important;
  }
}

.SidebarButtonCollapse {
  padding-right: 1.75rem;
}

.SidebarButtonExpand {
  padding: $buttonPaddingVertical;
  padding-left: 1rem;
  padding-right: 1.75rem;
  margin-right: 1rem;
  margin-left: -$bodySmallScreenPadding;

  @include media('>=widescreen') {
    margin-left: -$bodyPadding;
  }

  &--collapsed {
    display: none;
  }
}
