$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);
@import '@/styles/_globals';

.IoGameTile {
  position: relative;
  height: $gameTileHeight;
  width: $gameTileWidth;

  &--floating {
    position: absolute;
    z-index: 1;
  }

  &--hidden {
    opacity: 0;
    transform: scale(0.5);
  }

  &--featured {
    @include isDesktop {
      height: ($gameTileHeight * 2) + $gameTileGridGap; 
      width: ($gameTileWidth * 2) + $gameTileGridGap;
      min-width: ($gameTileWidth * 2) + $gameTileGridGap;
      max-width: ($gameTileWidth * 2) + $gameTileGridGap;
    }
  }

  @include isMobile {
    height: $gameTileMobileHeight !important;
    width: $gameTileMobileWidth !important;
    min-width: 0;
    max-width: none;
  }

  .GameTile__Preview {
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 40%;
      border-radius: 0 0 $radius $radius;
      z-index: 1;
    }
    
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: $radius;
      z-index: 2;
    }
  }

  &__Rating {
    height: 1rem;
  }
}

.GameTile__Status {
  position: static;
  padding: 0;

  @include media('>=desktop') {
    display: none;
  }
}

.IoGameTile__noMobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: $marginSm2;

  > span {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 90%;
    font-weight: bold;
    text-transform: uppercase;

    .Icon {
      width: 1.25rem;
      margin-right: $marginSm2;
    }
  }
}