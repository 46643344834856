$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);
@import '@/styles/_globals';

.AppHeader {
  position: inherit;
  
  &__Mobile {
    display: none;
    padding-top: $marginMd0;
    padding-bottom: $marginMd0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;

    @include media('<widescreen') {
      display: flex;
    }
  
    &__Logo {
      img:first-child {
        width: auto;
        min-width: 2.5rem;
        height: 2.5rem;

        @include media('>=tablet') {
          display: none;
        }
      }

      img:last-child {
        width: auto;
        height: 2.5rem;

        @include media('<tablet') {
          display: none;
        }
      }
    }

    .Autocomplete {
      .Icon {
        position: absolute;
        transition: all 200ms ease;
      }

      input {
        width: 100%;
        transition: all 200ms ease;

        &:not(:focus) {
          width: 50%;
          transform: translateX(50%);

          + .Icon {
            right: 50%;
            transform: translateX(50%)
          }
        }
      }
    }
  }
  
  &__Desktop {
    display: none;
    // padding-top: $marginSm1;
    // padding-bottom: $marginSm1;
	height: $DesktopHeaderHeight;

    @include media('>=widescreen') {
      display: flex;
    }
  
    &__Logo {
      width: auto;

      img {
        // height: 1.75rem;
		width: 21rem;
      }

      &--collapsed {
        margin-left: 3rem;
      }
    }

    &__Menu {
      padding-left: $emMd2;
      padding-right: $emMd2;
    }
  }
}

.Page {
  @include media('<widescreen') {
    padding-top: calc(#{$marginMd0 * 2} + 2.5rem); // 2.5rem == logo height
  }
}