$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);
@import '@/styles/_globals';

.CarouselBlock {
  position: relative;
  
  &:not(:last-child) {
    margin-bottom: $marginSm2;
  }

  &__Title {
    margin-bottom: $marginSm2;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  &__Content {
    position: relative;
  }

  &__ContentInner {
    position: relative;
    
    left: -$bodySmallScreenPadding;
    width: calc(100% + #{$bodySmallScreenPadding * 2});

    @include media('>=widescreen') {
      left: -$bodyPadding;
      width: calc(100% + #{$bodyPadding * 2});
    }
  }

  .Carousel {
    width: 100%;
    padding: 0 $bodySmallScreenPadding;

    @include isDesktop {
      margin: -0.5rem 0;
    }
  
    @include media('>=widescreen') {
      padding: 0 $bodyPadding;
    }

    &__Inner {
      // Mobile 1x1
      // display: flex;
      // @include isMobile {
      //   .IoGameTile {
      //     &:not(:last-child) {
      //       margin-right: $gameTileGridGap;
      //     }
      //   }
      // }
      // end Mobile 1x1

      // Mobile 2x2
      @include isMobile {
        display: grid;
        grid-gap: $gameTileGridGap;
        grid-auto-flow: column;
        grid-template-rows: $gameTileMobileHeight $gameTileMobileHeight; 
        grid-template-columns: repeat(auto-fill, $gameTileMobileWidth);
      }
      // end Mobile 2x2

      @include isDesktop {
        display: grid;
        grid-gap: $gameTileGridGap;
        grid-auto-flow: column;
        position: relative;
        z-index: 0;
        margin: 0.5rem 0;
        grid-template-rows: $gameTileHeight;
        grid-template-columns: $gameTileWidth;
        grid-auto-columns: max-content;
      }
    }
  }

  &--featured {
    @include isDesktop {
      .Carousel {
        &__Inner {
          display: grid;
          grid-template-rows: $gameTileHeight $gameTileHeight; 
          grid-template-columns: repeat(auto-fill, $gameTileWidth);

          .IoGameTile--featured {
            grid-row-end: span 2;
            grid-column-end: span 2;
          }
        }
      }
    }
  }
}

body.--menuOpen {
  @include media('>=widescreen') {
    .CarouselBlock {
      &__ContentInner {
        left: 0;
        width: calc(100% + #{$bodyPadding});
      }

      .Carousel {
        padding-left: 0;
        padding-right: $bodyPadding;

        &__Button--left::after {
          display: none;
        }
      }
    }
  }
}
