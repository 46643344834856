$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);
@import '@/styles/_globals';

.CollapsableSidebarLayout {
  .App__Main {
    margin: 1rem 0;

    @include isDesktop {
      margin: 1rem 0;
    }
  }

  &--sidebarCollapsed {
    &:not(.CollapsableSidebarLayout--mobileOnly) {
      .CollapsableSidebarLayout__Sidebar {
        display: none;
      }
    }
  }

  &:not(.CollapsableSidebarLayout--sidebarCollapsed) {
    .App__Main {
      .Container {
        @include isDesktop {
          padding-left: 0 !important;
        }
      }
    }
  }

  &__Sidebar {
    @include media('<widescreen') {
      display: none;
    }
  }

  &__MobileSidebar {
    position: fixed;
    z-index: 100;

    .Block:first-child {
      position: sticky;
      top: 0;
      z-index: 1;
    }

    @include media('>=widescreen') {
      display: none;
    }
  }

  &--mobileSidebarCollapsed {
    .CollapsableSidebarLayout__MobileSidebar {
      display: none;
    }
  }
}
