$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);
@import '@/styles/_globals';

.AppFooter {
  padding: $marginMd2 0;

  &--hasBannerAd {
    padding-bottom: calc(50px + #{$marginSm2 * 2});

    @include media('>=widescreen') {
      padding-bottom: calc(90px + #{$marginSm2 * 2})
    }
  }
  
  &__Inner {
    font-size: 80%;

    @include media('<widescreen') {
      text-align: center;
      
      .Flex {
        flex-direction: column;
        margin: $marginSm0 auto;
      }
  
      p {
        margin-bottom: 1rem;
      }
    }
  }

  &--collapsed {
    padding: 0;
    text-align: center;

    .AppFooter__Inner {
      .Flex {
        flex-direction: column;
        margin: $marginSm0 auto;
      }
  
      p {
        margin-bottom: 1rem;
      }
    }
  }

  &__BannerAd {
    position: fixed;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: 0;

    .ympb_target_banner {
      margin: $marginSm2 0;
    }
  }

  @include media('<=widescreen') {
    .RouterLink,
    .Button.Button--text {
      height: 44px;

      &::before,
      &::after {
        bottom: 26px;
      }
    }
  }
}
