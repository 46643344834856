$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);
@import '@/styles/_globals';

.IndexPage {
  position: inherit;
  
  &__FeaturedGamesTitle {
    margin-bottom: $marginSm2;
  }

  &__FeaturedGamesCarousel {
    position: relative;

    &__Inner {
      height: 14.2rem;
      overflow: hidden;
      margin: -($marginSm2 * 0.5);

      > .Flex {
        margin: ($marginSm2 * 0.5);
      }
    }
  }

  &__BottomBox {
    @include isDesktop {
      width: 70%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .GameTileBlock {
    .GameTileBlock__Title {
      justify-content: center;
    }
  
    .GameTileBlock__ContentInner {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}
